@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

.btn-discord-brand {
  @include button-variant(#5865f2, #5865f2);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.header {
  background-color: #22c4fa;
  background-image: url('../img/bg.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.navbar-brand {
  font-weight: 600;
  text-shadow: 0px 1px 5px rgba($color: #000000, $alpha: 0.2);
}

.navbar-brand-subtitle {
  font-size: 0.7em;
  font-weight: normal;
}

img.umineco-brand-image {
  border: 2px solid #fff;
  border-radius: var(--bs-border-radius);
  overflow: hidden;
  box-shadow: 0px 1px 5px rgba($color: #000000, $alpha: 0.2);
}

.jumbotron {
  // background: linear-gradient(130deg, #55c9ff, #00e9dd);
  background-color: #22c4fa;
  background-image: url('../img/bg.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 750px;
}

.jumbotron-title {
  color: #fff;
}

.jumbotron-active-member {
  display: inline-block;
  position: relative;
  background-color: #fff;
  border-radius: 20rem;
}

.jumbotron-active-member::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 11px;
  height: 11px;
  margin: 0 auto;
  rotate: 45deg;
}

.discord-join-button {
  display: inline-flex;
  align-items: center;
}

.discord-join-button::before {
  content: '';
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  margin-right: 0.5em;
  background-image: url('../img/discord-mark-white.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.member-count-loader {
  position: relative;
}

.member-count-loader > .member-count-loader-dummy {
  opacity: 0.2;
}

.member-count-loader-dummy::before {
  content: 'ただいま-人が参加中'
}

.member-count-loader > .spinner-border {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.umineco {
  display: block;
  position: relative;
  background: linear-gradient(130deg, #55c9ff, #00e9dd);
  overflow: hidden;
  border-radius: 100%;
  max-width: 80%;
  transition: border-radius 0.3s ease-in-out;
}

.umineco::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 100%;
}

.umineco::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -7%;
  left: 10%;
  width: 100%;
  height: 95%;
  margin: auto;
  background-image: url('../img/umineco.svg');
  background-size: cover;
  transition: left 0.3s 0.1s ease-in-out;
}

.umineco:hover {
  border-radius: 25%;
}

.umineco:hover::after {
  left: 7%;
}

.umineco-border {
  border: #fff solid 10px;
}

.section {
  padding: 5rem 0;
}

.section:nth-child(odd) {
  background-color: #f1feff;
}

.section-title {
  margin-bottom: 3rem;
}
.section-title > span {
  display: inline-block;
}

.role-header h3 {
  text-align: center;
}

.role-icon {
  width: 200px;
  height: 200px;
  margin: 2rem auto;
  background: linear-gradient(130deg, #55c9ff, #00e9dd);
  border-radius: 100%;
  padding: 30px;
}

.news-item-content {
  position: relative;
  display: block;
  background: #000;
}

.news-item-thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-item-header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .8));
  text-shadow: 0px 1px 5px rgba(0 ,0 ,0, 0.5);
}

.news-item-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .3s;
  transform: scale(1);
}

a.news-item-content:hover .news-item-thumbnail {
  transform: scale(1.1);
}

.news-read-more {
  background: #22c4fa;
  color: #fff;
  transition: 0.5s background;
  font-size: 1.35rem;
}

.news-read-more:hover {
  background: #16a6e9;
  color: #fff;
}

.news-read-more::after {
  content: '\00bb';
  margin-left: 0.5rem;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66.67%;
}

.invite-widget {
  background: #313338;
}

.invitation {
  background-image: radial-gradient(rgba(255, 255, 255, .6), 70%, #fff), url('../img/invitation_bg.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.online {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
}

.online::before, .online::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin: auto;
  border-radius: 20px;
  background: #43b581;
}

.online::before {
  animation: ring1 1.6s cubic-bezier(.69,.53,.65,.99) infinite;
}
.online::after {
  animation: ring2 1.6s cubic-bezier(.03,.41,.41,.92) infinite normal;
}
@keyframes ring1 {
  0% {
    transform:scale(1)
  }
  50% {
    transform:scale(1.25)
  }
  55% {
    transform:scale(1.05)
  }
  65% {
    transform:scale(1.02)
  }
  to {
    transform:scale(1)
  }
}
@keyframes ring2 {
  0% {
    opacity:0
  }
  50% {
    opacity:0
  }
  51% {
    transform:scale(1.3);
    opacity:.5
  }
  to {
    transform:scale(1.9);
    opacity:0
  }
}

@import "./content";
