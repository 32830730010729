.btn-discord-brand {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5865f2;
  --bs-btn-border-color: #5865f2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #4b56ce;
  --bs-btn-hover-border-color: #4651c2;
  --bs-btn-focus-shadow-rgb: 113, 124, 244;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4651c2;
  --bs-btn-active-border-color: #424cb6;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5865f2;
  --bs-btn-disabled-border-color: #5865f2;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.header {
  background-color: #22c4fa;
  background-image: url("bg.20468c23.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.navbar-brand {
  text-shadow: 0 1px 5px #0003;
  font-weight: 600;
}

.navbar-brand-subtitle {
  font-size: .7em;
  font-weight: 400;
}

img.umineco-brand-image {
  border-radius: var(--bs-border-radius);
  border: 2px solid #fff;
  overflow: hidden;
  box-shadow: 0 1px 5px #0003;
}

.jumbotron {
  background-color: #22c4fa;
  background-image: url("bg.20468c23.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 750px;
}

.jumbotron-title {
  color: #fff;
}

.jumbotron-active-member {
  background-color: #fff;
  border-radius: 20rem;
  display: inline-block;
  position: relative;
}

.jumbotron-active-member:after {
  content: "";
  background-color: #fff;
  width: 11px;
  height: 11px;
  margin: 0 auto;
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  rotate: 45deg;
}

.discord-join-button {
  align-items: center;
  display: inline-flex;
}

.discord-join-button:before {
  content: "";
  background-image: url("discord-mark-white.4c2acefe.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.4em;
  height: 1.4em;
  margin-right: .5em;
  display: inline-block;
}

.member-count-loader {
  position: relative;
}

.member-count-loader > .member-count-loader-dummy {
  opacity: .2;
}

.member-count-loader-dummy:before {
  content: "ただいま-人が参加中";
}

.member-count-loader > .spinner-border {
  margin: auto;
  position: absolute;
  inset: 0;
}

.umineco {
  background: linear-gradient(130deg, #55c9ff, #00e9dd);
  border-radius: 100%;
  max-width: 80%;
  transition: border-radius .3s ease-in-out;
  display: block;
  position: relative;
  overflow: hidden;
}

.umineco:before {
  height: 100%;
  padding-top: 100%;
}

.umineco:after, .umineco:before {
  content: "";
  width: 100%;
  display: block;
}

.umineco:after {
  background-image: url("umineco.85a08f14.svg");
  background-size: cover;
  height: 95%;
  margin: auto;
  transition: left .3s ease-in-out .1s;
  position: absolute;
  bottom: -7%;
  left: 10%;
}

.umineco:hover {
  border-radius: 25%;
}

.umineco:hover:after {
  left: 7%;
}

.umineco-border {
  border: 10px solid #fff;
}

.section {
  padding: 5rem 0;
}

.section:nth-child(odd) {
  background-color: #f1feff;
}

.section-title {
  margin-bottom: 3rem;
}

.section-title > span {
  display: inline-block;
}

.role-header h3 {
  text-align: center;
}

.role-icon {
  background: linear-gradient(130deg, #55c9ff, #00e9dd);
  border-radius: 100%;
  width: 200px;
  height: 200px;
  margin: 2rem auto;
  padding: 30px;
}

.news-item-content {
  background: #000;
  display: block;
  position: relative;
}

.news-item-thumbnail-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.news-item-header {
  text-shadow: 0 1px 5px #00000080;
  background-image: linear-gradient(#0000, #000c);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.news-item-thumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .3s;
  transform: scale(1);
}

a.news-item-content:hover .news-item-thumbnail {
  transform: scale(1.1);
}

.news-read-more {
  color: #fff;
  background: #22c4fa;
  font-size: 1.35rem;
  transition: background .5s;
}

.news-read-more:hover {
  color: #fff;
  background: #16a6e9;
}

.news-read-more:after {
  content: "»";
  margin-left: .5rem;
}

.ratio-3x2 {
  --bs-aspect-ratio: 66.67%;
}

.invite-widget {
  background: #313338;
}

.invitation {
  background-image: radial-gradient(#fff9, 70%, #fff), url("invitation_bg.59ca7ba2.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.online {
  width: 10px;
  height: 10px;
  display: inline-block;
  position: relative;
}

.online:after, .online:before {
  content: "";
  background: #43b581;
  border-radius: 20px;
  width: 10px;
  height: 10px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.online:before {
  animation: 1.6s cubic-bezier(.69, .53, .65, .99) infinite ring1;
}

.online:after {
  animation: 1.6s cubic-bezier(.03, .41, .41, .92) infinite ring2;
}

@keyframes ring1 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  55% {
    transform: scale(1.05);
  }

  65% {
    transform: scale(1.02);
  }

  to {
    transform: scale(1);
  }
}

@keyframes ring2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: .5;
    transform: scale(1.3);
  }

  to {
    opacity: 0;
    transform: scale(1.9);
  }
}

.content-header {
  text-shadow: 0 3px 5px #00000080;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-header-background {
  background-color: #0009;
  width: 100%;
  height: 100%;
  padding: 8rem 0;
}

.content-header-subtitle > a {
  color: #fff;
  text-decoration: none;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  margin-top: .5rem;
  margin-bottom: .6rem;
}

.content p + h1, .content p + h2, .content p + h3, .content p + h4, .content p + h5, .content p + h6 {
  margin-top: 1.5rem;
}

.content h1 {
  font-size: 2rem;
}

.content h2 {
  font-size: 1.8rem;
}

.content h3 {
  font-size: 1.3rem;
}

.content h4 {
  font-size: 1.2rem;
}

.content p {
  margin-bottom: .6rem;
}

.content picture {
  justify-content: center;
  max-width: 720px;
  margin: 1rem auto;
  display: flex;
}

/*# sourceMappingURL=style.css.map */
